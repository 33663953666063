import Vue from 'vue'
import SportsScoreToast from '@/components/administrator/SportsPanel/SportsScoreToast.vue'
let SportsToastToastConstructor = Vue.extend(SportsScoreToast) // 构造函数
let instance // 实例对象
let seed = 1 // 计数

const SportsScoreToastDialog = (options = {}) => {
    if (typeof options === 'string') {
        options = {
            id: 0,
            type:1
        }
    }
    let id = `sportsscoretoast_${seed++}`
    instance = new SportsToastToastConstructor({
        data: options
    })
    instance.id = id
    instance.vm = instance.$mount()
    document.body.appendChild(instance.vm.$el)
    instance.vm.visible = true
    instance.dom = instance.vm.$el
    instance.dom.style.zIndex = 999 + seed
    return instance.vm
}

export default SportsScoreToastDialog