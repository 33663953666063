<template>
    <el-dialog
            :title="title"
            :visible.sync="centerDialogVisible"
            width="50%"
            modal
            center @close="close">
        <div style="text-align: center;padding: 15px">
            베팅수:{{game.betQuantity|comma}} 베팅금액:{{game.betCashAmount|comma}}
        </div>
        <div style="">
            <p><i class="fa fa-star text-danger"></i> 전체취소는 버튼 `전체취소` 클릭시 전부 -1로 입력됩니다</p>
            <p><i class="fa fa-star text-danger"></i> 취소된 전반,후반,이닝,쿼터,세트,피어는 -1 로 입력하세요</p>
            <p><i class="fa fa-star text-danger"></i> -1 로 결과처리된 전반,후반,이닝,쿼터,세트,피어 를 베팅한 내역은 해당선택된 경기한개만
                취소처리 됩니다</p>
            <p><i class="fa fa-star text-danger"></i> -1 로 결과처리된 경기는 풀타임 베팅 전체를 취소처리 합니다 (연장전에서 취소된경우 핸디/오언은
                정상처리됨)</p>
            <p><i class="fa fa-star text-danger"></i> 예1) 축구가 전반전진행중 취소되였으면 전반,후반 홈팀&원정 모두 -1 </p>
            <p><i class="fa fa-star text-danger"></i> 예2) 축구가 후반전진행중 취소되였으면 전반전은 정상결과 입력 , 후반 홈팀&원정 모두 -1
            </p>
        </div>
        <div class="col-12 mt-1">
            <el-button type="warning" @click="initInplayResult()">초기화</el-button>
            <el-button type="danger" @click="cancelAllInplayGameResult()">전체취소</el-button>
        </div>

        <!--축구결과-->
        <div class="" style="padding-top: 10px;"
             v-if="game.apiTypeId === managerConst.Game.SPORTS_ID_SOCCER">
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
                <div>{{game.homeTeamName}}(전반 스코어)</div>
                <div>(전반 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.soccerHalf1HomeScore"
                          placeholder="전반 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.soccerHalf1AwayScore"
                          placeholder="전반 원정팀">
                </el-input>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;margin-top: 20px">
                <div>{{game.homeTeamName}}(후반 스코어)</div>
                <div>(후반 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input size="mini" type="number" v-model="inplayResult.soccerHalf2HomeScore"
                          placeholder="후반 홈팀"></el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.soccerHalf2AwayScore"
                          placeholder="후반 원정팀"></el-input>
            </div>
        </div>

        <!--하키결과-->
        <div class="" style="padding-top: 10px;"
             v-if="game.apiTypeId === managerConst.Game.SPORTS_ID_ICEHOKEY">
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
                <div>{{game.homeTeamName}}(1P 스코어)</div>
                <div>(1P 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.iceHockey1PHomeScore"
                          placeholder="1P 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.iceHockey1PAwayScore"
                          placeholder="1P 원정팀">
                </el-input>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;margin-top: 20px">
                <div>{{game.homeTeamName}}(2P 스코어)</div>
                <div>(2P 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input size="mini" type="number" v-model="inplayResult.iceHockey2PHomeScore"
                          placeholder="2P 홈팀"></el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.iceHockey2PAwayScore"
                          placeholder="2P 원정팀"></el-input>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;margin-top: 20px">
                <div>{{game.homeTeamName}}(3P 스코어)</div>
                <div>(3P 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input size="mini" type="number" v-model="inplayResult.iceHockey3PHomeScore"
                          placeholder="3P 홈팀"></el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.iceHockey3PAwayScore"
                          placeholder="3P 원정팀"></el-input>
            </div>
        </div>

        <!--농구결과-->
        <div class="" style="padding-top: 10px;"
             v-if="game.apiTypeId === managerConst.Game.SPORTS_ID_BASKETBALL">
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
                <div>{{game.homeTeamName}}(1쿼터 스코어)</div>
                <div>(1쿼터 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.basketball1QHomeScore"
                          placeholder="1Q 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.basketball1QAwayScore"
                          placeholder="1Q 원정팀">
                </el-input>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;margin-top: 20px">
                <div>{{game.homeTeamName}}(2쿼터 스코어)</div>
                <div>(2쿼터 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.basketball2QHomeScore"
                          placeholder="2Q 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.basketball2QAwayScore"
                          placeholder="2Q 원정팀">
                </el-input>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;margin-top: 20px">
                <div>{{game.homeTeamName}}(3쿼터 스코어)</div>
                <div>(3쿼터 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.basketball3QHomeScore"
                          placeholder="3Q 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.basketball3QAwayScore"
                          placeholder="3Q 원정팀">
                </el-input>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;margin-top: 20px">
                <div>{{game.homeTeamName}}(4쿼터 스코어)</div>
                <div>(43쿼터 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.basketball4QHomeScore"
                          placeholder="4Q 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.basketball4QAwayScore"
                          placeholder="4Q 원정팀">
                </el-input>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;margin-top: 20px">
                <div>{{game.homeTeamName}}(연장전 스코어)</div>
                <div>(연장전 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.basketball5QHomeScore"
                          placeholder="연장전 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.basketball5QAwayScore"
                          placeholder="연장전 원정팀">
                </el-input>
            </div>
        </div>


        <!--배구결과-->
        <div class="" style="padding-top: 10px;"
             v-if="game.apiTypeId === managerConst.Game.SPORTS_ID_VOLLEYBALL">
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
                <div>{{game.homeTeamName}}(1Set 스코어)</div>
                <div>(1Set 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.volleyball1SHomeScore"
                          placeholder="1Set 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.volleyball1SAwayScore"
                          placeholder="1Set 원정팀">
                </el-input>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;margin-top: 20px">
                <div>{{game.homeTeamName}}(2Set 스코어)</div>
                <div>(2Set 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.volleyball2SHomeScore"
                          placeholder="2Set 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.volleyball2SAwayScore"
                          placeholder="2Set 원정팀">
                </el-input>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;margin-top: 20px">
                <div>{{game.homeTeamName}}(3Set 스코어)</div>
                <div>(3Set 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.volleyball3SHomeScore"
                          placeholder="3Set 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.volleyball3SAwayScore"
                          placeholder="3Set 원정팀">
                </el-input>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;margin-top: 20px">
                <div>{{game.homeTeamName}}(4Set 스코어)</div>
                <div>(4Set 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.volleyball4SHomeScore"
                          placeholder="4Set 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.volleyball4SAwayScore"
                          placeholder="4Set 원정팀">
                </el-input>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;margin-top: 20px">
                <div>{{game.homeTeamName}}(5Set 스코어)</div>
                <div>(5Set 스코어){{game.awayTeamName}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">

                <el-input type="number" size="mini" v-model="inplayResult.volleyball5SHomeScore"
                          placeholder="5Set 홈팀">
                </el-input>
                <span style="display: inline-block;width: 10px"> </span>
                <el-input type="number" size="mini" v-model="inplayResult.volleyball5SAwayScore"
                          placeholder="5Set 원정팀">
                </el-input>
            </div>
        </div>

        <!--야구결과-->
        <div class="" style="padding-top: 10px;"
             v-if="game.apiTypeId === managerConst.Game.SPORTS_ID_BASEBALL">
            <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
                <div>{{game.homeTeamName}}</div>
            </div>
            <div class="baseballscore">
                <el-input type="number" size="mini" v-model="inplayResult.baseball1NHomeScore"
                          placeholder="1N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball2NHomeScore"
                          placeholder="2N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball3NHomeScore"
                          placeholder="3N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball4NHomeScore"
                          placeholder="4N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball5NHomeScore"
                          placeholder="5N">
                </el-input>
            </div>
            <div class="baseballscore">

                <el-input type="number" size="mini" v-model="inplayResult.baseball6NHomeScore"
                          placeholder="6N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball7NHomeScore"
                          placeholder="7N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball8NHomeScore"
                          placeholder="8N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball9NHomeScore"
                          placeholder="9N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball10NHomeScore"
                          placeholder="10N">
                </el-input>
            </div>
            <div class="baseballscore">
                <el-input type="number" size="mini" v-model="inplayResult.baseball11NHomeScore"
                          placeholder="11N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball12NHomeScore"
                          placeholder="12N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball13NHomeScore"
                          placeholder="13N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball14NHomeScore"
                          placeholder="14N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball15NHomeScore"
                          placeholder="15N">
                </el-input>
            </div>
            <div class="baseballscore">
            <el-input type="number" size="mini" v-model="inplayResult.baseball16NHomeScore"
                          placeholder="16N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball17NHomeScore"
                          placeholder="17N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball18NHomeScore"
                          placeholder="18N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball19NHomeScore"
                          placeholder="19N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball20NHomeScore"
                          placeholder="20N">
                </el-input>
            </div>


            <div class="baseballscore" style="margin-top: 20px">
                <div>{{game.awayTeamName}}</div>
            </div>
            <div class="baseballscore">
                <el-input type="number" size="mini" v-model="inplayResult.baseball1NAwayScore"
                          placeholder="1N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball2NAwayScore"
                          placeholder="2N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball3NAwayScore"
                          placeholder="3N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball4NAwayScore"
                          placeholder="4N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball5NAwayScore"
                          placeholder="5N">
                </el-input>
            </div>
            <div class="baseballscore">

                <el-input type="number" size="mini" v-model="inplayResult.baseball6NAwayScore"
                          placeholder="6N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball7NAwayScore"
                          placeholder="7N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball8NAwayScore"
                          placeholder="8N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball9NAwayScore"
                          placeholder="9N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball10NAwayScore"
                          placeholder="10N">
                </el-input>
            </div>
            <div class="baseballscore">
                <el-input type="number" size="mini" v-model="inplayResult.baseball11NAwayScore"
                          placeholder="11N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball12NAwayScore"
                          placeholder="12N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball13NAwayScore"
                          placeholder="13N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball14NAwayScore"
                          placeholder="14N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball15NAwayScore"
                          placeholder="15N">
                </el-input>
            </div>
            <div class="baseballscore">
                <el-input type="number" size="mini" v-model="inplayResult.baseball16NAwayScore"
                          placeholder="16N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball17NAwayScore"
                          placeholder="17N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball18NAwayScore"
                          placeholder="18N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball19NAwayScore"
                          placeholder="19N">
                </el-input>
                <el-input type="number" size="mini" v-model="inplayResult.baseball20NAwayScore"
                          placeholder="20N">
                </el-input>
            </div>
        </div>


        <span slot="footer" class="dialog-footer">
        <el-button @click="close">취소</el-button>
        <el-button type="primary" @click="updateInplayGameResult(game.apiTypeId,game.id)">확인</el-button>
        </span>
    </el-dialog>

</template>

<script>
    import {getById, updateGameResult, updateInplayGameScores} from "../../../network/manager/sportsRequest";
    import managerConst from "../../../common/administrator/managerConst";

    export default {
        name: "InplayScoreToast",
        data() {
            return {
                gameId: null, //
                duration: 0, //
                closed: false,
                centerDialogVisible: false,
                game: {},
                title: '',
                homeScore: null,
                awayScore: null,
                sysn: false,
                inplayResult: {
                    //하키
                    iceHockey1PHomeScore: null,
                    iceHockey1PAwayScore: null,
                    iceHockey2PHomeScore: null,
                    iceHockey2PAwayScore: null,
                    iceHockey3PHomeScore: null,
                    iceHockey3PAwayScore: null,
                    iceHockey4PHomeScore: 0,
                    iceHockey4PAwayScore: 0,
                    iceHockey5PAwayScore: 0,
                    iceHockey5PHomeScore: 0,

                    //농구결과
                    basketball1HalfAwayScore: 0,
                    basketball1HalfHomeScore: 0,

                    basketball1QAwayScore: null,
                    basketball1QHomeScore: null,

                    basketball2QAwayScore: null,
                    basketball2QHomeScore: null,

                    basketball3QAwayScore: null,
                    basketball3QHomeScore: null,

                    basketball4QHomeScore: null,
                    basketball4QAwayScore: null,

                    basketball5QAwayScore: 0,
                    basketball5QHomeScore: 0,

                    //축구결과
                    soccerHalf1HomeScore: null,
                    soccerHalf1AwayScore: null,
                    soccerHalf2HomeScore: null,
                    soccerHalf2AwayScore: null,

                    //배구결과
                    volleyball1SHomeScore: null,
                    volleyball1SAwayScore: null,

                    volleyball2SAwayScore: null,
                    volleyball2SHomeScore: null,

                    volleyball3SHomeScore: null,
                    volleyball3SAwayScore: null,

                    volleyball4SHomeScore: null,
                    volleyball4SAwayScore: null,

                    volleyball5SHomeScore: null,
                    volleyball5SAwayScore: null,

                    //야구결과
                    baseball1NHomeScore: null,
                    baseball1NAwayScore: null,

                    baseball2NAwayScore: null,
                    baseball2NHomeScore: null,

                    baseball3NAwayScore: null,
                    baseball3NHomeScore: null,

                    baseball4NHomeScore: null,
                    baseball4NAwayScore: null,

                    baseball5NAwayScore: null,
                    baseball5NHomeScore: null,

                    baseball6NHomeScore: null,
                    baseball6NAwayScore: null,

                    baseball7NHomeScore: null,
                    baseball7NAwayScore: null,

                    baseball8NHomeScore: null,
                    baseball8NAwayScore: null,

                    baseball9NHomeScore: null,
                    baseball9NAwayScore: null,

                    baseball10NHomeScore: 0,
                    baseball10NAwayScore: 0,

                    baseball11NHomeScore: 0,
                    baseball11NAwayScore: 0,

                    baseball12NHomeScore: 0,
                    baseball12NAwayScore: 0,

                    baseball13NAwayScore: 0,
                    baseball13NHomeScore: 0,

                    baseball14NAwayScore: 0,
                    baseball14NHomeScore: 0,

                    baseball15NHomeScore: 0,
                    baseball15NAwayScore: 0,

                    baseball16NAwayScore: 0,
                    baseball16NHomeScore: 0,

                    baseball17NHomeScore: 0,
                    baseball17NAwayScore: 0,

                    baseball18NHomeScore: 0,
                    baseball18NAwayScore: 0,

                    baseball19NHomeScore: 0,
                    baseball19NAwayScore: 0,

                    baseball20NHomeScore: 0,
                    baseball20NAwayScore: 0,
                },
                managerConst: managerConst

            }
        },
        created() {
            this.centerDialogVisible = true
            getById(this.gameId).then(res => {
                if (res.data.success) {
                    this.game = res.data.data
                    this.title = '(' + this.game.league.leagueName + ') ' + this.game.homeTeamName + ' : ' + this.game.awayTeamName
                }
            })
        },
        mounted() {

        },
        watch: {
            closed(newVal) {
                if (newVal) {
                    this.game = {}
                    this.visible = false
                    this.destroyElement()
                }
            }
        },
        methods: {
            close() {
                this.game = {}
                this.closed = true
                this.centerDialogVisible = false
            },
            setScore() {
                let record = {}
                record.id = this.gameId
                record.homeTeamScore = this.homeScore
                record.awayTeamScore = this.awayScore
                updateGameResult(record).then(res => {
                    if (res.data.success) {
                        let gameResult = 0
                        getById(this.gameId).then(res => {
                            gameResult = res.data.data.gameResult
                            this.$bus.$emit('sportsScoreSuccess', {
                                'gameId': this.gameId,
                                'hs': this.homeScore,
                                'as': this.awayScore,
                                'gameResult': gameResult
                            })
                        })

                    } else {
                        this.$bus.$emit('sportsScoreSuccess', {'gameId': 0, 'hs': this.homeScore, 'as': this.awayScore})
                    }
                })

                this.close();

            },
            destroyElement() {
                this.$destroy()
                this.$el.parentNode.removeChild(this.$el)
            },
            initInplayResult() {
                this.inplayResult = {
                    //하키
                    iceHockey1PHomeScore: null,
                    iceHockey1PAwayScore: null,
                    iceHockey2PHomeScore: null,
                    iceHockey2PAwayScore: null,
                    iceHockey3PHomeScore: null,
                    iceHockey3PAwayScore: null,
                    iceHockey4PHomeScore: 0,
                    iceHockey4PAwayScore: 0,
                    iceHockey5PAwayScore: 0,
                    iceHockey5PHomeScore: 0,

                    //농구결과
                    basketball1HalfAwayScore: 0,
                    basketball1HalfHomeScore: 0,

                    basketball1QAwayScore: null,
                    basketball1QHomeScore: null,

                    basketball2QAwayScore: null,
                    basketball2QHomeScore: null,

                    basketball3QAwayScore: null,
                    basketball3QHomeScore: null,

                    basketball4QHomeScore: null,
                    basketball4QAwayScore: null,

                    basketball5QAwayScore: 0,
                    basketball5QHomeScore: 0,

                    //축구결과
                    soccerHalf1HomeScore: null,
                    soccerHalf1AwayScore: null,
                    soccerHalf2HomeScore: null,
                    soccerHalf2AwayScore: null,

                    //배구결과
                    volleyball1SHomeScore: null,
                    volleyball1SAwayScore: null,

                    volleyball2SAwayScore: null,
                    volleyball2SHomeScore: null,

                    volleyball3SHomeScore: null,
                    volleyball3SAwayScore: null,

                    volleyball4SHomeScore: null,
                    volleyball4SAwayScore: null,

                    volleyball5SHomeScore: null,
                    volleyball5SAwayScore: null,

                    //야구결과
                    baseball1NHomeScore: null,
                    baseball1NAwayScore: null,

                    baseball2NAwayScore: null,
                    baseball2NHomeScore: null,

                    baseball3NAwayScore: null,
                    baseball3NHomeScore: null,

                    baseball4NHomeScore: null,
                    baseball4NAwayScore: null,

                    baseball5NAwayScore: null,
                    baseball5NHomeScore: null,

                    baseball6NHomeScore: null,
                    baseball6NAwayScore: null,

                    baseball7NHomeScore: null,
                    baseball7NAwayScore: null,

                    baseball8NHomeScore: null,
                    baseball8NAwayScore: null,

                    baseball9NHomeScore: null,
                    baseball9NAwayScore: null,

                    baseball10NHomeScore: 0,
                    baseball10NAwayScore: 0,

                    baseball11NHomeScore: 0,
                    baseball11NAwayScore: 0,

                    baseball12NHomeScore: 0,
                    baseball12NAwayScore: 0,

                    baseball13NAwayScore: 0,
                    baseball13NHomeScore: 0,

                    baseball14NAwayScore: 0,
                    baseball14NHomeScore: 0,

                    baseball15NHomeScore: 0,
                    baseball15NAwayScore: 0,

                    baseball16NAwayScore: 0,
                    baseball16NHomeScore: 0,

                    baseball17NHomeScore: 0,
                    baseball17NAwayScore: 0,

                    baseball18NHomeScore: 0,
                    baseball18NAwayScore: 0,

                    baseball19NHomeScore: 0,
                    baseball19NAwayScore: 0,

                    baseball20NHomeScore: 0,
                    baseball20NAwayScore: 0,
                }
            },
            updateInplayGameResult(apiTypeId,gameId){
                let flag = this.checkInplayResult(apiTypeId);
                if (flag) {
                    let game = {}
                    game.scores = JSON.stringify(this.inplayResult)
                    //game.hits = JSON.stringify($scope.inplayHitResult)
                    game.apiTypeId = apiTypeId
                    game.id = gameId
                    updateInplayGameScores(game).then(res=>{
                        if (res.data.success) {
                            this.$bus.$emit('inplayScoresInserted', {'issuccess': true})
                        } else {
                            this.$bus.$emit('inplayScoresInserted', {'issuccess': true,'msg':response.data.msg})
                        }
                        this.close()
                    });
                }
            },
            cancelAllInplayGameResult() {
                this.inplayResult = {
                    //하키
                    iceHockey1PHomeScore: -1,
                    iceHockey1PAwayScore: -1,
                    iceHockey2PHomeScore: -1,
                    iceHockey2PAwayScore: -1,
                    iceHockey3PHomeScore: -1,
                    iceHockey3PAwayScore: -1,
                    iceHockey4PHomeScore: -1,
                    iceHockey4PAwayScore: -1,
                    iceHockey5PAwayScore: -1,
                    iceHockey5PHomeScore: -1,

                    //농구결과
                    basketball1HalfAwayScore: -1,
                    basketball1HalfHomeScore: -1,

                    basketball1QAwayScore: -1,
                    basketball1QHomeScore: -1,

                    basketball2QAwayScore: -1,
                    basketball2QHomeScore: -1,

                    basketball3QAwayScore: -1,
                    basketball3QHomeScore: -1,

                    basketball4QHomeScore: -1,
                    basketball4QAwayScore: -1,

                    basketball5QAwayScore: -1,
                    basketball5QHomeScore: -1,

                    //축구결과
                    soccerHalf1HomeScore: -1,
                    soccerHalf1AwayScore: -1,
                    soccerHalf2HomeScore: -1,
                    soccerHalf2AwayScore: -1,

                    //배구결과
                    volleyball1SHomeScore: -1,
                    volleyball1SAwayScore: -1,

                    volleyball2SAwayScore: -1,
                    volleyball2SHomeScore: -1,

                    volleyball3SHomeScore: -1,
                    volleyball3SAwayScore: -1,

                    volleyball4SHomeScore: -1,
                    volleyball4SAwayScore: -1,

                    volleyball5SHomeScore: -1,
                    volleyball5SAwayScore: -1,

                    //야구결과
                    baseball1NHomeScore: -1,
                    baseball1NAwayScore: -1,

                    baseball2NAwayScore: -1,
                    baseball2NHomeScore: -1,

                    baseball3NAwayScore: -1,
                    baseball3NHomeScore: -1,

                    baseball4NHomeScore: -1,
                    baseball4NAwayScore: -1,

                    baseball5NAwayScore: -1,
                    baseball5NHomeScore: -1,

                    baseball6NHomeScore: -1,
                    baseball6NAwayScore: -1,

                    baseball7NHomeScore: -1,
                    baseball7NAwayScore: -1,

                    baseball8NHomeScore: -1,
                    baseball8NAwayScore: -1,

                    baseball9NHomeScore: -1,
                    baseball9NAwayScore: -1,

                    baseball10NHomeScore: -1,
                    baseball10NAwayScore: -1,

                    baseball11NHomeScore: -1,
                    baseball11NAwayScore: -1,

                    baseball12NHomeScore: -1,
                    baseball12NAwayScore: -1,

                    baseball13NAwayScore: -1,
                    baseball13NHomeScore: -1,

                    baseball14NAwayScore: -1,
                    baseball14NHomeScore: -1,

                    baseball15NHomeScore: -1,
                    baseball15NAwayScore: -1,

                    baseball16NAwayScore: -1,
                    baseball16NHomeScore: -1,

                    baseball17NHomeScore: -1,
                    baseball17NAwayScore: -1,

                    baseball18NHomeScore: -1,
                    baseball18NAwayScore: -1,

                    baseball19NHomeScore: -1,
                    baseball19NAwayScore: -1,

                    baseball20NHomeScore: -1,
                    baseball20NAwayScore: -1,
                }
            },
            checkInplayResult (apiTypeId) {
                //축구체크
                if (apiTypeId === managerConst.Game.SPORTS_ID_SOCCER) {
                    if (this.inplayResult.soccerHalf1HomeScore == null) {
                        alert('홈팀 전반 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.soccerHalf2HomeScore == null) {
                        alert('홈팀 후반 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.soccerHalf1AwayScore == null) {
                        alert('원정팀 전반 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.soccerHalf2AwayScore == null) {
                        alert('원정팀 후반 스코어 필수입력');
                        return false
                    }
                    return true;
                }

                //농구체크
                if (apiTypeId === managerConst.Game.SPORTS_ID_BASKETBALL) {
                    if (this.inplayResult.basketball1QHomeScore == null) {
                        alert('홈팀 1Q 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.basketball2QHomeScore == null) {
                        alert('홈팀 2Q 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.basketball3QHomeScore == null) {
                        alert('홈팀 3Q 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.basketball4QHomeScore == null) {
                        alert('홈팀 4Q 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.basketball5QHomeScore == null) {
                        alert('홈팀 연장Q 스코어 필수입력');
                        return false
                    }

                    if (this.inplayResult.basketball1QAwayScore == null) {
                        alert('원정팀 1Q 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.basketball2QAwayScore == null) {
                        alert('원정팀 2Q 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.basketball3QAwayScore == null) {
                        alert('원정팀 3Q 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.basketball4QAwayScore == null) {
                        alert('원정팀 4Q 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.basketball5QAwayScore == null) {
                        alert('원정팀 연장Q 스코어 필수입력');
                        return false
                    }

                    return true;
                }

                //하키
                if (apiTypeId === managerConst.Game.SPORTS_ID_ICEHOKEY) {
                    if (this.inplayResult.iceHockey1PHomeScore == null) {
                        alert('홈팀 1피어 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.iceHockey2PHomeScore == null) {
                        alert('홈팀 2피어 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.iceHockey3PHomeScore == null) {
                        alert('홈팀 3피어 스코어 필수입력');
                        return false
                    }

                    if (this.inplayResult.iceHockey1PAwayScore == null) {
                        alert('원정팀 1피어 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.iceHockey2PAwayScore == null) {
                        alert('원정팀 2피어 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.iceHockey3PAwayScore == null) {
                        alert('원정팀 3피어 스코어 필수입력');
                        return false
                    }

                    return true;
                }

                //배구체크
                if (apiTypeId === managerConst.Game.SPORTS_ID_VOLLEYBALL) {
                    if (this.inplayResult.volleyball1SHomeScore == null) {
                        alert('홈팀 1Set 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.volleyball2SHomeScore == null) {
                        alert('홈팀 2Set 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.volleyball3SHomeScore == null) {
                        alert('홈팀 3Set 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.volleyball4SHomeScore == null) {
                        alert('홈팀 4Set 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.volleyball5SHomeScore == null) {
                        alert('홈팀 5Set 스코어 필수입력');
                        return false
                    }

                    if (this.inplayResult.volleyball1SAwayScore == null) {
                        alert('원정팀 1Set 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.volleyball2SAwayScore == null) {
                        alert('원정팀 2Set 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.volleyball3SAwayScore == null) {
                        alert('원정팀 3Set 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.volleyball4SAwayScore == null) {
                        alert('원정팀 4Set 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.volleyball5SAwayScore == null) {
                        alert('원정팀 5Set 스코어 필수입력');
                        return false
                    }

                    return true;
                }

                //아구
                if (apiTypeId === managerConst.Game.SPORTS_ID_BASEBALL) {
                    if (this.inplayResult.baseball1NHomeScore == null) {
                        alert('홈팀 1이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball2NHomeScore == null) {
                        alert('홈팀 2이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball3NHomeScore == null) {
                        alert('홈팀 3이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball4NHomeScore == null) {
                        alert('홈팀 4이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball5NHomeScore == null) {
                        alert('홈팀 5이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball6NHomeScore == null) {
                        alert('홈팀 6이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball7NHomeScore == null) {
                        alert('홈팀 7이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball8NHomeScore == null) {
                        alert('홈팀 8이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball9NHomeScore == null) {
                        alert('홈팀 9이닝 스코어 필수입력');
                        return false
                    }

                    if (this.inplayResult.baseball1NAwayScore == null) {
                        alert('원정팀 1이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball2NAwayScore == null) {
                        alert('원정팀 2이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball3NAwayScore == null) {
                        alert('원정팀 3이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball4NAwayScore == null) {
                        alert('원정팀 4이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball5NAwayScore == null) {
                        alert('원정팀 5이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball6NAwayScore == null) {
                        alert('원정팀 6이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball7NAwayScore == null) {
                        alert('원정팀 7이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball8NAwayScore == null) {
                        alert('원정팀 8이닝 스코어 필수입력');
                        return false
                    }
                    if (this.inplayResult.baseball9NAwayScore == null) {
                        alert('원정팀 9이닝 스코어 필수입력');
                        return false
                    }


                    return true;
                }
            }
        }
    }
</script>

<style scoped>
    .baseballscore {
        display: flex;justify-content: flex-start;flex-wrap: nowrap
    }
    .baseballscore .el-input{
        margin: 0 1px;
    }
</style>