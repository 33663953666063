import Vue from 'vue'
import SportsBetInfoToast from '@/components/administrator/SportsPanel/SportsBetInfoToast.vue'

let SportsEditToastConstructor = Vue.extend(SportsBetInfoToast) // 构造函数
let instance // 实例对象
let seed = 1 // 计数

const SportsBetInfoToastDialog = (options = {}) => {
    if (typeof options === 'string') {
        options = {
            game: {},
        }
    }
    let id = `sportsscoretoast_${seed++}`
    instance = new SportsEditToastConstructor({
        data: options
    })
    instance.id = id
    instance.vm = instance.$mount()
    document.body.appendChild(instance.vm.$el)
    instance.vm.visible = true
    instance.dom = instance.vm.$el
    instance.dom.style.zIndex = 999 + seed
    return instance.vm
}

export default SportsBetInfoToastDialog