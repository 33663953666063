<template>
    <div>
        <p v-if="data.league.gameTypeId===managerConst.GameType.SOCCER && data.halfStatus > 1">
            <span class="text-green" style="display:inline-block;width: 180px">{{data.homeTeamName}}</span>
            전:(<span :class="{'text-blue':data.scores.soccerHalf1HomeScore>0}">{{data.scores.soccerHalf1HomeScore}}</span>)
            후:(<span :class="{'text-blue':data.scores.soccerHalf2HomeScore>0}">{{data.scores.soccerHalf2HomeScore}}</span>)
        </p>
        <p v-if="data.league.gameTypeId===managerConst.GameType.SOCCER && data.halfStatus > 1">
            <span class="text-green" style="display:inline-block;width: 180px">{{data.awayTeamName}}</span>
            전:(<span :class="{'text-blue':data.scores.soccerHalf1AwayScore>0}">{{data.scores.soccerHalf1AwayScore}}</span>)
            후:(<span :class="{'text-blue':data.scores.soccerHalf2AwayScore>0}">{{data.scores.soccerHalf2AwayScore}}</span>)
        </p>
        <p v-if="data.league.gameTypeId===managerConst.GameType.BASKETBALL">
            <span class="text-green" style="display:inline-block;width: 180px">{{data.homeTeamName}}</span>
            1Q:(<span :class="{'text-blue':data.scores.basketball1QHomeScore>0}">{{data.scores.basketball1QHomeScore}}</span>)
            2Q:(<span :class="{'text-blue':data.scores.basketball2QHomeScore>0}">{{data.scores.basketball2QHomeScore}}</span>)
            3Q:(<span :class="{'text-blue':data.scores.basketball3QHomeScore>0}">{{data.scores.basketball3QHomeScore}}</span>)
            4Q:(<span :class="{'text-blue':data.scores.basketball4QHomeScore>0}">{{data.scores.basketball4QHomeScore}}</span>)
        </p>
        <p v-if="data.league.gameTypeId===managerConst.GameType.BASKETBALL">
            <span class="text-green" style="display:inline-block;width: 180px">{{data.awayTeamName}}</span>
            1Q:(<span :class="{'text-blue':data.scores.basketball1QAwayScore>0}">{{data.scores.basketball1QAwayScore}}</span>)
            2Q:(<span :class="{'text-blue':data.scores.basketball2QAwayScore>0}">{{data.scores.basketball2QAwayScore}}</span>)
            3Q:(<span :class="{'text-blue':data.scores.basketball3QAwayScore>0}">{{data.scores.basketball3QAwayScore}}</span>)
            4Q:(<span :class="{'text-blue':data.scores.basketball4QAwayScore>0}">{{data.scores.basketball4QAwayScore}}</span>)
        </p>

        <p v-if="data.league.gameTypeId===managerConst.GameType.VOLLEYBALL">
            <span class="text-green" style="display:inline-block;width: 180px">{{data.homeTeamName}}</span>
            1Set:(<span :class="{'text-blue':data.scores.volleyball1SHomeScore>0}">{{data.scores.volleyball1SHomeScore}}</span>)
            2Set:(<span :class="{'text-blue':data.scores.volleyball2SHomeScore>0}">{{data.scores.volleyball2SHomeScore}}</span>)
            3Set:(<span :class="{'text-blue':data.scores.volleyball3SHomeScore>0}">{{data.scores.volleyball3SHomeScore}}</span>)
            4Set:(<span :class="{'text-blue':data.scores.volleyball4SHomeScore>0}">{{data.scores.volleyball4SHomeScore}}</span>)
            5Set:(<span :class="{'text-blue':data.scores.volleyball5SHomeScore>0}">{{data.scores.volleyball5SHomeScore}}</span>)
        </p>
        <p v-if="data.league.gameTypeId===managerConst.GameType.VOLLEYBALL">
            <span class="text-green" style="display:inline-block;width: 180px">{{data.awayTeamName}}</span>
            1Set:(<span :class="{'text-blue':data.scores.volleyball1SAwayScore>0}">{{data.scores.volleyball1SAwayScore}}</span>)
            2Set:(<span :class="{'text-blue':data.scores.volleyball2SAwayScore>0}">{{data.scores.volleyball2SAwayScore}}</span>)
            3Set:(<span :class="{'text-blue':data.scores.volleyball3SAwayScore>0}">{{data.scores.volleyball3SAwayScore}}</span>)
            4Set:(<span :class="{'text-blue':data.scores.volleyball4SAwayScore>0}">{{data.scores.volleyball4SAwayScore}}</span>)
            5Set:(<span :class="{'text-blue':data.scores.volleyball5SAwayScore>0}">{{data.scores.volleyball5SAwayScore}}</span>)
        </p>

        <p v-if="data.league.gameTypeId===managerConst.GameType.ICEHOCKEY">
            <span class="text-green" style="display:inline-block;width: 180px">{{data.homeTeamName}}</span>
            1P:(<span :class="{'text-blue':data.scores.iceHockey1PHomeScore>0}">{{data.scores.iceHockey1PHomeScore}}</span>)
            2P:(<span :class="{'text-blue':data.scores.iceHockey2PHomeScore>0}">{{data.scores.iceHockey2PHomeScore}}</span>)
            3P:(<span :class="{'text-blue':data.scores.iceHockey3PHomeScore>0}">{{data.scores.iceHockey3PHomeScore}}</span>)
        </p>
        <p v-if="data.league.gameTypeId===managerConst.GameType.ICEHOCKEY">
            <span class="text-green" style="display:inline-block;width: 180px">{{data.awayTeamName}}</span>
            1P:(<span :class="{'text-blue':data.scores.iceHockey1PAwayScore>0}">{{data.scores.iceHockey1PAwayScore}}</span>)
            2P:(<span :class="{'text-blue':data.scores.iceHockey2PAwayScore>0}">{{data.scores.iceHockey2PAwayScore}}</span>)
            3P:(<span :class="{'text-blue':data.scores.iceHockey3PAwayScore>0}">{{data.scores.iceHockey3PAwayScore}}</span>)
        </p>

        

        <p v-if="data.league.gameTypeId===managerConst.GameType.BASEBALLL">
            <span class="text-green" style="display:inline-block;width: 180px">{{data.homeTeamName}}</span>
            1N:(<span :class="{'text-blue':data.scores.baseball1NHomeScore>0}">{{data.scores.baseball1NHomeScore}}</span>)
            2N:(<span :class="{'text-blue':data.scores.baseball2NHomeScore>0}">{{data.scores.baseball2NHomeScore}}</span>)
            3N:(<span :class="{'text-blue':data.scores.baseball3NHomeScore>0}">{{data.scores.baseball3NHomeScore}}</span>)
            4N:(<span :class="{'text-blue':data.scores.baseball4NHomeScore>0}">{{data.scores.baseball4NHomeScore}}</span>)
            5N:(<span :class="{'text-blue':data.scores.baseball5NHomeScore>0}">{{data.scores.baseball5NHomeScore}}</span>)
            6N:(<span :class="{'text-blue':data.scores.baseball6NHomeScore>0}">{{data.scores.baseball6NHomeScore}}</span>)
            7N:(<span :class="{'text-blue':data.scores.baseball7NHomeScore>0}">{{data.scores.baseball7NHomeScore}}</span>)
            8N:(<span :class="{'text-blue':data.scores.baseball8NHomeScore>0}">{{data.scores.baseball8NHomeScore}}</span>)
            9N:(<span :class="{'text-blue':data.scores.baseball9NHomeScore>0}">{{data.scores.baseball9NHomeScore}}</span>)
            10N:(<span :class="{'text-blue':data.scores.baseball10NHomeScore>0}">{{data.scores.baseball10NHomeScore}}</span>)
            11N:(<span :class="{'text-blue':data.scores.baseball11NHomeScore>0}">{{data.scores.baseball11NHomeScore}}</span>)
            12N:(<span :class="{'text-blue':data.scores.baseball12NHomeScore>0}">{{data.scores.baseball12NHomeScore}}</span>)
            13N:(<span :class="{'text-blue':data.scores.baseball13NHomeScore>0}">{{data.scores.baseball13NHomeScore}}</span>)
            14N:(<span :class="{'text-blue':data.scores.baseball14NHomeScore>0}">{{data.scores.baseball14NHomeScore}}</span>)
            15N:(<span :class="{'text-blue':data.scores.baseball15NHomeScore>0}">{{data.scores.baseball15NHomeScore}}</span>)
            16N:(<span :class="{'text-blue':data.scores.baseball16NHomeScore>0}">{{data.scores.baseball16NHomeScore}}</span>)
            17N:(<span :class="{'text-blue':data.scores.baseball17NHomeScore>0}">{{data.scores.baseball17NHomeScore}}</span>)
            18N:(<span :class="{'text-blue':data.scores.baseball18NHomeScore>0}">{{data.scores.baseball18NHomeScore}}</span>)
            19N:(<span :class="{'text-blue':data.scores.baseball19NHomeScore>0}">{{data.scores.baseball19NHomeScore}}</span>)
            20N:(<span :class="{'text-blue':data.scores.baseball20NHomeScore>0}">{{data.scores.baseball20NHomeScore}}</span>)
        </p>
        <p v-if="data.league.gameTypeId===managerConst.GameType.BASEBALLL">
            <span class="text-green" style="display:inline-block;width: 180px">{{data.awayTeamName}}</span>
            1N:(<span :class="{'text-blue':data.scores.baseball1NAwayScore>0}">{{data.scores.baseball1NAwayScore}}</span>)
            2N:(<span :class="{'text-blue':data.scores.baseball2NAwayScore>0}">{{data.scores.baseball2NAwayScore}}</span>)
            3N:(<span :class="{'text-blue':data.scores.baseball3NAwayScore>0}">{{data.scores.baseball3NAwayScore}}</span>)
            4N:(<span :class="{'text-blue':data.scores.baseball4NAwayScore>0}">{{data.scores.baseball4NAwayScore}}</span>)
            5N:(<span :class="{'text-blue':data.scores.baseball5NAwayScore>0}">{{data.scores.baseball5NAwayScore}}</span>)
            6N:(<span :class="{'text-blue':data.scores.baseball6NAwayScore>0}">{{data.scores.baseball6NAwayScore}}</span>)
            7N:(<span :class="{'text-blue':data.scores.baseball7NAwayScore>0}">{{data.scores.baseball7NAwayScore}}</span>)
            8N:(<span :class="{'text-blue':data.scores.baseball8NAwayScore>0}">{{data.scores.baseball8NAwayScore}}</span>)
            9N:(<span :class="{'text-blue':data.scores.baseball9NAwayScore>0}">{{data.scores.baseball9NAwayScore}}</span>)
            10N:(<span :class="{'text-blue':data.scores.baseball10NAwayScore>0}">{{data.scores.baseball10NAwayScore}}</span>)
            11N:(<span :class="{'text-blue':data.scores.baseball11NAwayScore>0}">{{data.scores.baseball11NAwayScore}}</span>)
            12N:(<span :class="{'text-blue':data.scores.baseball12NAwayScore>0}">{{data.scores.baseball12NAwayScore}}</span>)
            13N:(<span :class="{'text-blue':data.scores.baseball13NAwayScore>0}">{{data.scores.baseball13NAwayScore}}</span>)
            14N:(<span :class="{'text-blue':data.scores.baseball14NAwayScore>0}">{{data.scores.baseball14NAwayScore}}</span>)
            15N:(<span :class="{'text-blue':data.scores.baseball15NAwayScore>0}">{{data.scores.baseball15NAwayScore}}</span>)
            16N:(<span :class="{'text-blue':data.scores.baseball16NAwayScore>0}">{{data.scores.baseball16NAwayScore}}</span>)
            17N:(<span :class="{'text-blue':data.scores.baseball17NAwayScore>0}">{{data.scores.baseball17NAwayScore}}</span>)
            18N:(<span :class="{'text-blue':data.scores.baseball18NAwayScore>0}">{{data.scores.baseball18NAwayScore}}</span>)
            19N:(<span :class="{'text-blue':data.scores.baseball19NAwayScore>0}">{{data.scores.baseball19NAwayScore}}</span>)
            20N:(<span :class="{'text-blue':data.scores.baseball20NAwayScore>0}">{{data.scores.baseball20NAwayScore}}</span>)
        </p>


    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import managerConst from "../../common/administrator/managerConst";

    export default {
        name: "ScoreComp",
        data() {
            return {
                managerConst: managerConst
            }
        },
        props: {
            data: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
    }
</script>

<style scoped>

</style>