<template>
    <div class="gametypes">
        <span v-if="gameTypeId === managerConst.GameType.SOCCER">
                                    <img alt="축구" src="../../assets/images/icon/gametype/football-ico.png"
                                         style="width: 21px;">
                                </span>
        <span v-if="gameTypeId === managerConst.GameType.BASEBALLL">
                                    <img alt="야구" src="../../assets/images/icon/gametype/baseball-ico.png"
                                         style="width: 21px;">
                                </span>
        <span v-if="gameTypeId === managerConst.GameType.BASKETBALL">
                                    <img alt="농구" src="../../assets/images/icon/gametype/basketball-ico.png"
                                         style="width: 21px;">
                                </span>
        <span v-if="gameTypeId === managerConst.GameType.VOLLEYBALL">
                                    <img alt="배구" src="../../assets/images/icon/gametype/volleyball-ico.png"
                                         style="width: 21px;">
                                </span>
        <span v-if="gameTypeId === managerConst.GameType.ICEHOCKEY">
                                    <img alt="하키" src="../../assets/images/icon/gametype/hockey-ico.png"
                                         style="width: 21px;">
                                </span>
        <span v-if="gameTypeId === managerConst.GameType.FOOTBALL">
                                    <img alt="미식축구" src="../../assets/images/icon/gametype/usa-football-ico.png"
                                         style="width: 21px;">
                                </span>
        <span v-if="gameTypeId === managerConst.GameType.TENNIS">
                                    <img alt="테니스" src="../../assets/images/icon/gametype/tennis-ico.png"
                                         style="width: 21px;">
                                </span>
        <span v-if="gameTypeId === managerConst.GameType.ESPORTS">
                                    <img alt="esports" src="../../assets/images/icon/gametype/esport-ico.png"
                                         style="width: 21px;">
                                </span>
        <span v-if="gameTypeId === managerConst.GameType.UFC">
                                    격투기
                                </span>
        <span v-if="gameTypeId === managerConst.GameType.OTHER">
                                    기타
                                </span>
    </div>
</template>

<script>
    import managerConst from "../../common/administrator/managerConst";

    export default {
        name: "GameTypeImageComp",
        props: {
            gameTypeId: {
                type: Number,
                default() {
                    return managerConst.GameType.SOCCER;
                }
            },
           
        },
        data(){
            return {
                managerConst:managerConst
            }
        }
    }
</script>

<style scoped>
    .gametypes{
        display: inline-block;
        width: 28px!important;
    }
    .gametypes img{
        vertical-align: middle;
    }

</style>