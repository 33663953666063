<template>
    <el-dialog title="베팅내역" :visible.sync="outerVisible" @close="close" width="75%">
        <h5>경기정보</h5>
        <el-table
                :data="gameList"
                style="width: 100%"
                max-height="750"
                border>
            <!--더보기-->
            <el-table-column type="expand" label="더보기" width="60">
                <template slot-scope="props">
                    <el-form label-position="left" class="demo-table-expand">
                        <el-form-item>
                            {{props.row.homeTeamName}} <span class="text-green">vs</span>
                            {{props.row.awayTeamName}}
                        </el-form-item>
                        <el-form-item label="ID">
                            <span>{{ props.row.id }}</span>
                        </el-form-item>
                        <el-form-item label="UC" v-if="props.row.isinplay === managerConst.Game.INPLAY_NOT">
                            <span>{{ props.row.unifiedCode }}</span>
                        </el-form-item>
                        <el-form-item label="API ID"
                                      v-if="props.row.isinplay === managerConst.Game.INPLAY_YES">
                            <span>{{ props.row.apiId }}</span>
                        </el-form-item>
                        <el-form-item label="진행코어"
                                      v-if="props.row.isinplay === managerConst.Game.INPLAY_YES">
                            <span>{{ props.row.halfStatus }}</span>
                        </el-form-item>
                        <el-form-item label="진행시간"
                                      v-if="props.row.isinplay === managerConst.Game.INPLAY_YES">
                            <span>{{ props.row.timemm }}"</span>
                        </el-form-item>
                        <el-form-item label="실시간 스코어"
                                      v-if="props.row.isinplay === managerConst.Game.INPLAY_YES"></el-form-item>
                        <el-form-item v-if="props.row.isinplay === managerConst.Game.INPLAY_YES">
                            <score-comp :data="props.row"></score-comp>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <!--상태-->
            <el-table-column
                    fixed
                    label="상태"
                    width="45">
                <template slot-scope="scope">
                    <span v-if="scope.row.status === managerConst.Game.STATUS_CHECKING">대기</span>
                    <span v-if="scope.row.status === managerConst.Game.STATUS_OPEN">가능</span>
                    <span v-if="scope.row.status === managerConst.Game.STATUS_STARTING">마감</span>
                    <span v-if="scope.row.status === managerConst.Game.STATUS_END">종료</span>
                </template>
            </el-table-column>
            <!--시작일짜-->
            <el-table-column
                    fixed
                    label="시작일"
                    width="90">
                <template slot-scope="scope">
                    <span :class="{'bg-warning' : scope.row.autoclosed === managerConst.NOT}">{{scope.row.startTime|datef('DD일 HH:mm')}}</span>
                </template>
            </el-table-column>
            <!--종목-->
            <el-table-column
                    fixed
                    label="종목"
                    width="50">
                <template slot-scope="scope">
                    <game-type-image-comp :game-type-id="scope.row.gameTypeId"></game-type-image-comp>
                </template>
            </el-table-column>
            <!--리그-->
            <el-table-column
                    fixed
                    label="리그"
                    width="230">
                <template slot-scope="scope">
                    <div class="text-left">
                        <img :src="scope.row.league.imageUrl"
                             style="width: 28px;vertical-align: middle">
                        <span style="margin-left: 5px">{{scope.row.league.leagueName}}</span>
                        <span>({{scope.row.leagueId}})</span>
                    </div>
                </template>
            </el-table-column>
            <!--홈팀명-->
            <el-table-column
                    fixed
                    label="T1"
                    width="150">
                <template slot-scope="scope">
                    <div class="text-left"
                         style="cursor: pointer">
                        <i class="fa fa-television text-green"
                           v-if="scope.row.isinplay === managerConst.Game.INPLAY_YES"></i>
                        <i class="fa fa-trophy text-blue"
                           v-if="scope.row.gameResult === managerConst.Game.GAME_RESULT_WIN_HOME"></i>
                        <span v-html="scope.row.homeTeamName"></span>
                    </div>
                </template>
            </el-table-column>
            <!--홈팀배당-->
            <el-table-column
                    fixed
                    label="T1B"
                    width="90">
                <template slot-scope="scope">
                    <div>{{scope.row.homeTeamOdds}}</div>
                </template>
            </el-table-column>
            <!--무,핸디,오버언더 기준치-->
            <el-table-column
                    fixed
                    label="D.H.O"
                    width="90">
                <template slot-scope="scope">
                    <div v-if="scope.row.kind === managerConst.Game.GAME_KIND_1X2">
                        <i class="fa fa-trophy text-blue"
                           v-if="scope.row.gameResult === managerConst.Game.GAME_RESULT_DRAW"></i>
                        <span>{{scope.row.drawOdds}}</span>

                    </div>
                    <div class="text-blue" v-if="scope.row.kind === managerConst.Game.GAME_KIND_HANDICAP">
                        <i class="fa fa-h-square"></i> ({{scope.row.handicapVal}})
                    </div>
                    <div v-if="scope.row.kind === managerConst.Game.GAME_KIND_OVERUNDER">
                        <i class="fa fa-arrow-circle-up text-red"></i>
                        <i class="fa fa-arrow-circle-down text-blue"></i>
                        ({{scope.row.overunderVal}})
                    </div>
                </template>
            </el-table-column>
            <!--원정배당-->
            <el-table-column
                    fixed
                    label="T2B"
                    width="90">
                <template slot-scope="scope">
                    <div>{{scope.row.awayTeamOdds}}</div>
                </template>
            </el-table-column>
            <!--원정팀명-->
            <el-table-column
                    fixed
                    label="T2"
                    width="150">
                <template slot-scope="scope">
                    <div class="text-right"
                         style="cursor: pointer">
                        <span v-html="scope.row.awayTeamName"></span>
                        <i class="fa fa-trophy text-blue"
                           v-if="scope.row.gameResult === managerConst.Game.GAME_RESULT_WIN_AWAY"></i>
                        <i class="fa fa-television text-green"
                           v-if="scope.row.isinplay === managerConst.Game.INPLAY_YES"></i>
                    </div>
                </template>
            </el-table-column>
            <!--베팅수-->
            <el-table-column
                    fixed
                    label="베팅수"
                    width="80">
                <template slot-scope="scope">
                    <span style="width: 100%;padding: 2px 0;cursor: pointer;">{{scope.row.betQuantity|comma}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="베팅금"
                    width="100">
                <template slot-scope="scope">
                    <span style="width: 100%;padding: 2px 0;cursor: pointer;">{{scope.row.betCashAmount|comma}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="스코어"
                    width="110">
                <template slot-scope="scope">
                    <div v-if="scope.row.homeTeamScore >-1 &&scope.row.awayTeamScore >-1
                                && scope.row.isinplay === managerConst.Game.INPLAY_NOT">
                        {{scope.row.homeTeamScore + ':' + scope.row.awayTeamScore}}
                    </div>
                    <span class="text-red" style="display: block"
                          v-if="scope.row.gameResult === managerConst.Game.GAME_RESULT_CANCELED">
                                    취소
                                </span>
                    <span class="text-red" style="display: block"
                          v-if="scope.row.gameResult === managerConst.Game.GAME_RESULT_SPECIAL">
                                    적특
                                </span>
                </template>
            </el-table-column>

        </el-table>
        <h5>베팅정보</h5>
        <sports-bet-info-comp :key="2000" :list="sportsBetList" @refresh="refresh"></sports-bet-info-comp>

    </el-dialog>
</template>

<script>
    import managerConst from "../../../common/administrator/managerConst";
    import {listSportsBetInfoByGameId} from "../../../network/manager/sportsBetRequest";
    import GameTypeImageComp from "../GameTypeImageComp";
    import SportsBetInfoComp from "../SportsBetInfoComp";

    export default {
        name: "SportsBetInfoToast",
        components: {SportsBetInfoComp, GameTypeImageComp},
        data() {
            return {
                game: {},
                gameList: [],
                sportsBet: {},
                sportsBetList: [],
                closed: false,
                outerVisible: false,
                innerVisible: false,
                managerConst: managerConst,
                pageNum: 1,
                pageSize: 10,
                orderBy: 'id DESC',
                pageTotal: 0,
            }
        },
        methods: {
            close() {
                this.closed = true
                this.outerVisible = false
            },
            getSportsBetListByGameId(gameId, selectedVal) {
                this.sportsBet.gameId = this.game.id
                listSportsBetInfoByGameId(gameId, selectedVal).then(res => {
                    this.sportsBetList = res.data.data.betList;
                })

            },
            refresh() {
                this.getSportsBetListByGameId(this.game.id, 0)
            },
            destroyElement() {
                this.$destroy()
                this.$el.parentNode.removeChild(this.$el)
            },
        },
        created() {
            this.outerVisible = true
            if (null != this.game) {
                this.gameList.push(this.game)
                this.getSportsBetListByGameId(this.game.id, 0)
            }

        },
        mounted() {

        },
        watch: {
            closed(newVal) {
                if (newVal) {
                    this.game = {}
                    this.gameList = []
                    this.sportsBet = {}
                    this.sportsBetList = []
                    this.outerVisible = false
                    this.innerVisible = false
                    this.destroyElement()
                }
            }
        },

    }
</script>

<style scoped>
    .gameinfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .g1 {
        margin-top: 5px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #b3d8ff;
        padding: 5px;
        border-radius: 3px;
    }
</style>