import Vue from 'vue'
import InplayScoreToast from '@/components/administrator/SportsPanel/InplayScoreToast.vue'
let InPlayScoreToastConstructor = Vue.extend(InplayScoreToast) // 构造函数
let instance // 实例对象
let seed = 1 // 计数

const InplayScoreToastDialog = (options = {}) => {
    if (typeof options === 'string') {
        options = {
            id: 0
        }
    }
    let id = `inplayscoretoast_${seed++}`
    instance = new InPlayScoreToastConstructor({
        data: options
    })
    instance.id = id
    instance.vm = instance.$mount()
    document.body.appendChild(instance.vm.$el)
    instance.vm.visible = true
    instance.dom = instance.vm.$el
    instance.dom.style.zIndex = 999 + seed
    return instance.vm
}

export default InplayScoreToastDialog